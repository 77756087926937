import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "cards"
    }}>{`Cards`}</h1>
    <p>{`The Alert is a combination of `}<inlineCode parentName="p">{`.card`}</inlineCode>{` and `}<inlineCode parentName="p">{`border-color`}</inlineCode>{` classes.`}</p>
    <p>To see all the colors and their variations go to the <a className='pink' href='/styleguide'>Styleguide page</a></p>
    <div className='card border-pink black' style={{
      "maxWidth": "300px"
    }}>
  <h6>
    Title
  </h6>
  <p className='m-0'>
    Some text
  </p>
    </div>
    <div className='card border-lilac black' style={{
      "maxWidth": "300px"
    }}>
  <h6>
    Title
  </h6>
  <p className='m-0'>
    Some text
  </p>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='card border-pink black'>
  <!-- Some HTML Here -->
</div>
<div class='card border-lilac black'>
  <!-- Some HTML Here -->
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      